<template>
  <div class="productInformation">
    <div class="banner">
      <div class="content">
        <div class="title" data-aos="fade-up">{{$t('productInformation.bannerText')}}</div>
      </div>
    </div>
    <div class="bannerMb">
      <div class="content">
        <div class="title" data-aos="fade-up">{{$t('productInformation.bannerText')}}</div>
      </div>
    </div>
    <div class="conentBox">
      <img class="w1" src="@/assets/w1.png" alt="">
      <img class="w2" v-if="productsdata.length > 5" src="@/assets/w2.png" alt="">
      <img class="w3" v-if="productsdata.length > 5" src="@/assets/w3.png" alt="">
      <img class="w4" v-if="productsdata.length > 5" src="@/assets/w4.png" alt="">
      <div class="content">
        <div class="list" v-for="(item,index) in productsdata" :key="index" @click="openPdf(item)" :data-aos="index%2 == 0?'fade-right':'fade-left'">
          <div class="imgBox" v-if="index%2 == 1" >
            <img class="img" :src="baseHostUrl + item.imgurl2" alt="">
          </div>
          <div class="info">
            <div class="title">{{item.title}}</div>

            <div class="text">{{item.desc_list}}</div>
            <div class="moreM">
              <div class="t">{{ $t('productInformation.more') }}</div>
              <img class="cpj" src="@/assets/cpj.png" alt="">
            </div>
          </div>
          <div class="imgBox" v-if="index%2 == 0" >
            <img class="img" :src="baseHostUrl + item.imgurl2" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="conentBoxMb">

      <div class="conent">
        <div class="list" v-for="(item,index) in productsdata" :key="index" @click="openPdf(item)" data-aos="fade-up">
          <div class="imgBox" >
            <img class="img" :src="baseHostUrl + item.imgurl2" alt="">
          </div>
          <div class="info">
            <div class="title">{{item.title}}</div>
            <div class="time">{{item.happen_times}}</div>
            <div class="text">{{item.desc_list}}</div>
            <div class="moreM" :style="{marginBottom: index%2 == 0?'0':'20px'}">
              <div class="t">{{ $t('productInformation.more') }}</div>
              <img class="cpj" src="@/assets/cpj.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="noData" v-if="noData">{{ $t('productInformation.moreNo') }}</div>
  </div>
</template>
<script>
import {baseHostUrl,getproductsdata} from '@/utils/index.js'
export default {
  name: 'productInformation',
  components: {

  },
  data () {
    return {
      pcOrmb: true,
      page: 1,
      productsdata: [],
      baseHostUrl: '',
      count: 0,
      noData: false
    }
  },
  computed: {
  },
  created() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {  
      this.pcOrmb = false
    }else{  
      this.pcOrmb = true
    } 
  },
  mounted() {
		document.title = '云脉芯联';
    this.getproductsdata()
    this.baseHostUrl = baseHostUrl()
  },
  methods: {
    openPdf(item){
      console.log(this.$i18n.locale);
          this.$router.push('/detalisCN?id=' + item.id)
      // if (this.$i18n.locale == 'EN') {
      //   if (item.id == 6) {
          // this.$router.push('/detalisENs?id=' + item.id)
      //   } else {
      //     this.$router.push('/detalisEN?id=' + item.id)
      //   }
       
        
      // } else {
      //   window.open(item.product,"_bank")
      // }
    },
    async getproductsdata() {
      let data = {
        type: localStorage.getItem('language').toLowerCase(),
        page: this.page,
        limit: 10
      }
      
      let res = await getproductsdata(data)
      this.productsdata = this.productsdata.concat(res.data.list)
      this.count = res.data.count
      if (res.data.list.length < 10){
        this.noData = true
      }
    },
    onMoreData() {
      if (this.count > this.productsdata.length) {
        this.page++
        this.getproductsdata()
      } else {
        this.noData = true
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.dynamic{
  background: #F6F7F9;
}
.banner{
  background: url('../assets/bannercp.jpg') no-repeat;
  height: 31vw;
  background-size: cover;
  display: flex;
  align-items: center;
  .content{
    width: 63vw;
    margin: auto;
    .title{
      font-size: 1.8vw;
      font-weight: 700;
      color: #fff;
      text-align: left;
    }
  }
}
.bannerMb{
  display: none;
}
.content{
  width: 63vw;
  margin: auto;
  padding: 1vw 0 3vw 0;
  position: relative;
  .list{
    display: flex;
    padding: 3vw 0;
    border-bottom: solid 1px #E9E9E9;
    cursor: pointer;
    &:last-child{
      border-bottom: none;
    }
    .imgBox{
      margin: 0 1vw;
      width: 24vw;
      flex: 0 0 24vw;
      height: 14vw;
      overflow: hidden;
      .img{
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-out;
      }
      &:hover{
        .img{
          transform: scale(1.1);
          transition: all 0.3s ease-out;
        }
      }
    }
    .info{
      padding: 0 1vw;
      flex: 1;
      .title{
        font-size:22px;
        color: #333333;
      }
 
      .text{
        font-size: 14px;
        color: #666666;
        line-height: 26px;
        margin-top: 33px;
      }
      .moreM{
        font-size: 12px;
        color: #2E73B5;
        margin-top: 33px;
        display: flex;
        align-items: center;
        .cpj{
          width: 22px;
          height: 7px;
          margin-left: 10px;
        }
      }
    }
  }
}
.conentBoxMb{
  display: none;
}
.more{
  text-align: center;
  font-size: 0.6vw;
  padding-bottom: 3vw;
  cursor: pointer;
  .img{
    margin: 1vw auto auto auto;
  }
}
.conentBox{
  position: relative;
}
.w1{
  position: absolute;
  top: 0;
  left: 0;
  width: 20vw;
}
.w2{
  position: absolute;
  top: 30%;
  right: 0;
  width: 20vw;
}
.w3{
  position: absolute;
  top: 60%;
  left: 0;
  width: 20vw;
}
.w4{
  position: absolute;
  bottom:0;
  right: 0;
  width: 20vw;
}
@media screen and (max-width:750px){
  .banner{
    display: none;
  }
  .bannerMb{
    background: url('../assets/bannercpMb.png') no-repeat;
    height: 67vw;
    background-size: cover;
    display: flex;
    align-items: center;
    .content{
      padding:  6vw;
      flex: 1;
      .title{
        font-size: 7vw;
        font-weight: 700;
        color: #fff;
        text-align: right;
      }
    }
  }
  .conentBox{
    display: none;
  }
  .conentBoxMb{
    display: block;
    padding: 0 5vw;
    .conent{
      margin: auto;
      padding: 4vw 0 3vw 0;
      position: relative;
      .list{
        padding: 7vw 0;
        border-bottom: solid 1px #E9E9E9;
        cursor: pointer;
        &:last-child{
          border-bottom: none;
        }
        .imgBox{
          padding: 0 1vw;
          .img{
            width: 100%;
            transition: all 0.3s ease-out;
          }
          &:hover{
            .img{
              transform: scale(1.1);
              transition: all 0.3s ease-out;
            }
          }
        }
        .info{
          padding: 0 1vw;
          .title{
            font-size: 5.5vw;
            color: #333333;
            margin-top: 4vw;
          }
          .time{
            font-size: 3vw;
            margin: 3vw 0;
            color: #999999;
          }
          .text{
            font-size: 4vw;
            color: #666666;
            line-height: 2;
          }
          .moreM{
            font-size: 12px;
            color: #2E73B5;
            margin: 20px 0;
            display: flex;
            align-items: center;
            .cpj{
              width: 22px;
              height: 7px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .w1{
    position: absolute;
    top: 0;
    left: 0;
    width: 60vw;
  }
  .w2{
    position: absolute;
    top: 30%;
    right: 0;
    width: 60vw;
  }
  .w3{
    position: absolute;
    top: 60%;
    left: 0;
    width: 60vw;
  }
  .w4{
    position: absolute;
    bottom:0;
    right: 0;
    width: 60vw;
  }
}
.noData{
    text-align: center;
    font-size: 0.6vw;
    padding-bottom: 3vw;
}
</style>